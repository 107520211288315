exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ameliyatsiz-estetik-index-js": () => import("./../../../src/pages/ameliyatsiz-estetik/index.js" /* webpackChunkName: "component---src-pages-ameliyatsiz-estetik-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cilt-hastaliklari-index-js": () => import("./../../../src/pages/cilt-hastaliklari/index.js" /* webpackChunkName: "component---src-pages-cilt-hastaliklari-index-js" */),
  "component---src-pages-doc-index-js": () => import("./../../../src/pages/doc/index.js" /* webpackChunkName: "component---src-pages-doc-index-js" */),
  "component---src-pages-iletisim-thanks-js": () => import("./../../../src/pages/iletisim/thanks.js" /* webpackChunkName: "component---src-pages-iletisim-thanks-js" */),
  "component---src-pages-lazer-tedavileri-index-js": () => import("./../../../src/pages/lazer-tedavileri/index.js" /* webpackChunkName: "component---src-pages-lazer-tedavileri-index-js" */),
  "component---src-pages-tr-iletisim-thanks-js": () => import("./../../../src/pages/tr/iletisim/thanks.js" /* webpackChunkName: "component---src-pages-tr-iletisim-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-body-info-js": () => import("./../../../src/templates/body-info.js" /* webpackChunkName: "component---src-templates-body-info-js" */),
  "component---src-templates-body-post-js": () => import("./../../../src/templates/body-post.js" /* webpackChunkName: "component---src-templates-body-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-doc-post-js": () => import("./../../../src/templates/doc-post.js" /* webpackChunkName: "component---src-templates-doc-post-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-laser-info-js": () => import("./../../../src/templates/laser-info.js" /* webpackChunkName: "component---src-templates-laser-info-js" */),
  "component---src-templates-laser-post-js": () => import("./../../../src/templates/laser-post.js" /* webpackChunkName: "component---src-templates-laser-post-js" */),
  "component---src-templates-skin-info-js": () => import("./../../../src/templates/skin-info.js" /* webpackChunkName: "component---src-templates-skin-info-js" */),
  "component---src-templates-skin-post-js": () => import("./../../../src/templates/skin-post.js" /* webpackChunkName: "component---src-templates-skin-post-js" */)
}

